import React from "react";
import { Layout } from "../components/common";
import { MetaData } from "../components/common/meta";
import { Row, Col } from "reactstrap";

const Categories = ({ data, location, pageContext }) => {
    const tags = data.GajCMS.get_all_tags.filter((tag) => tag.post_count > 0);

    return (
        <>
            <MetaData location={location} />
            <Layout>
                <h1 className="special-heading mb-5 text-center my-2">
                    All Categories
                </h1>
                <Row className="post-feed w-50 mx-auto">
                    {tags.map((tag) => (
                        <Col className="col-4 text-center">
                            <a href={"/tag/" + tag.slug}>
                                <u>{tag.name}</u>
                            </a>
                        </Col>
                    ))}
                </Row>
            </Layout>
        </>
    );
};

export default Categories;

export const query = graphql`
    query {
        GajCMS {
            get_all_tags {
                id
                name
                slug
                post_count
            }
        }
    }
`;
